import axios from 'axios';
import nookies from 'nookies';
import apiService from './apiService';

const AppServices = {
  getListApp: async ({ keys = [], startWith = null }) => {
    const uri = startWith ? `/settings/list-app/?startwith=${startWith}` : `/settings/list-app/${keys.length > 0 ? `?key=${keys.join(',')}` : ''}`;
    const { data } = await apiService.get(uri);
    return data;
  },
  getGeoIp: async () => {
    try {
      // if (
      //   !window.location.hostname.includes('assemblrworld.com')
      //   && !window.location.hostname.includes('localhost')
      //   && !window.location.hostname.includes('firman.asia')
      // ) {
      //   return {
      //     country: navigator.language?.split('-')[1]?.toLowerCase?.(),
      //   };
      // }

      const cookies = nookies.get();
      const country = cookies.geo_pref;

      if (country && String(country) !== 'null') {
        return {
          country: country.toLowerCase?.(),
        };
      }

      const { data } = await axios.get(process.env.NEXT_PUBLIC_GEOIP_API_URL);

      nookies.set({}, 'geo_pref', data?.geo?.country || null, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        ...(window.location.hostname.includes('assemblrworld.com')
          ? { domain: 'assemblrworld.com' }
          : { domain: window.location.hostname.split('.').slice(-2).join('.') }),
      });

      return {
        country: data?.geo?.country?.toLowerCase?.() || null,
      };
    } catch (e) {
      return {
        country: null,
      };
    }
  },
  userGeoIp: () => {
    const cookies = nookies.get();
    return {
      country: cookies?.geo_pref?.toLowerCase?.() || null,
    };
  },
  getShortenUrl: async ({ url,
    utm_source = null,
    utm_medium = null,
    utm_campaign = 'shortenurl',
  }) => {
    const _url = new URL(url);
    const _params = new URLSearchParams(_url.search);
    if (utm_source) _params.set('utm_source', utm_source);
    if (utm_medium) _params.set('utm_medium', utm_medium);
    if (utm_campaign) _params.set('utm_campaign', utm_campaign);
    const finalUrl = `${_url.origin}${_url.pathname}?${_params.toString()}`;
    try {
      const { data } = await apiService.post('/shorten_link/', { url: finalUrl });
      return data?.url_hash || finalUrl;
    } catch {
      return finalUrl;
    }
  },
};

export default AppServices;
