import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Head from 'next/head';
import localFont from 'next/font/local';
import classNames from 'classnames';
import Script from 'next/script';
import Store from '../src/Config/Store';

import '../src/index.scss';
import 'assemblr-ui/dist/index.css';
import '../src/App.css';
import '../src/Component/Content/index.css';
import '../src/Component/ContentGenerator/index.css';
import '../src/Component/CreateProject/index.css';
import '../src/Component/Creation/index.css';
import '../src/Component/Creation/index.scss';
import '../src/Component/DeleteAccount/index.css';
import '../src/Component/Loading/index.css';
import '../src/Component/Login/index.scss';
import '../src/Component/Login/integration/VRWARE/index.scss';
import '../src/Component/Main/Account/index.css';
import '../src/Component/Main/Account/index.scss';
import '../src/Component/Main/AccountSetting/index.css';
import '../src/Component/Main/AccountSetting2/index.css';
import '../src/Component/Main/Class/Create/index.css';
import '../src/Component/Main/Class/Detail/index.css';
import '../src/Component/Main/Class/DetailClass2/MobilePost/index.css';
import '../src/Component/Main/Class/DetailClass2/index.css';
import '../src/Component/Main/Class/DetailPost/index.css';
import '../src/Component/Main/Class/MaterialPage/index.css';
import '../src/Component/Main/Class/MemberPage/index.css';
import '../src/Component/Main/Class/Setting/index.css';
import '../src/Component/Main/Class/index.css';
import '../src/Component/Main/Class/index.scss';
import '../src/Component/Main/CustomMarker/Onboarding/index.css';
import '../src/Component/Main/CustomMarker/index.css';
import '../src/Component/Main/Notification/index.css';
import '../src/Component/Main/OtherAccount/index.css';
import '../src/Component/Main/Shop/index.css';
import '../src/Component/Main/Topics/Detail/index.css';
import '../src/Component/Main/Topics/index.css';
import '../src/Component/Main/index.css';
import '../src/Component/Main/index.scss';
import '../src/Component/MyPlan/index.css';
import '../src/Component/NotificationCenter/index.css';
import '../src/Component/NotificationSetting/index.css';
import '../src/Component/Onboarding/Component/CardOption/index.scss';
import '../src/Component/Onboarding/Component/Checkbox/index.scss';
import '../src/Component/Onboarding/Component/ChildCardOption/index.scss';
import '../src/Component/Onboarding/Component/CustomAutoComplete/index.scss';
import '../src/Component/Onboarding/Component/NewCardVideo/index.scss';
import '../src/Component/Onboarding/Component/OtherOption/index.scss';
import '../src/Component/Onboarding/Component/Overlay/index.scss';
import '../src/Component/Onboarding/onboarding.scss';
import '../src/Component/Onboarding/profileCompletion.scss';
import '../src/Component/Plans/index.css';
import '../src/Component/Prelogin/index.css';
import '../src/Component/Publish/index.css';
import '../src/Component/PublishOption/EmbedMenu/index.css';
import '../src/Component/PublishOption/PublishClass/index.css';
import '../src/Component/PublishOption/PublishLink/index.css';
import '../src/Component/PublishOption/PublishMarker/index.css';
import '../src/Component/PublishOption/PublishSettings/index.css';
import '../src/Component/PublishOption/index.css';
import '../src/Component/QRCode/index.css';
import '../src/Component/Redeem/index.css';
import '../src/Component/Redeem/index.scss';
import '../src/Component/Register/Custom/index.css';
import '../src/Component/Register/Facebook/index.css';
import '../src/Component/Register/index.css';
import '../src/Component/ResetPassword/index.css';
import '../src/Component/Search/index.css';
import '../src/Component/Template/AstuDialog/index.css';
import '../src/Component/Template/ButtonIcon/index.css';
import '../src/Component/Template/ConfirmationDialog/index.css';
import '../src/Component/Template/Dialog/index.css';
import '../src/Component/Template/DownloadAppDialog/index.css';
import '../src/Component/Template/Footer/index.css';
import '../src/Component/Template/LoadingBar/index.css';
import '../src/Component/Template/LoadingSpinner/index.css';
import '../src/Component/Template/MetaViewer/index.css';
import '../src/Component/Template/NavbarModul/index.css';
import '../src/Component/Template/Notification/index.css';
import '../src/Component/Template/ShareDialog/index.css';
import '../src/Component/Template/ShareDialog/index.scss';
import '../src/Component/Template/UpgradeDialog/index.css';
import '../src/Component/Transaction/index.css';
import '../src/Component/Update/index.css';
import '../src/Config/icon.css';
import '../src/resolver.scss';

import Seo from '../components/Seo';
import ErrorBoundary from '../components/ErrorBoundary';
import initializeFirebase from '../src/Function/InitializeFirebase';

const { store, persistor } = Store;

initializeFirebase();

const blricons = localFont({
  src: '../src/Asset/fonts/blricons/blricons.woff2',
  display: 'swap',
  variable: '--font-blricons',
});

if (process.env.NODE_ENV === 'production' && !process.env.NEXT_PUBLIC_EDU_URL.includes('pr-')) {
  console.log = () => { };
}

export default function Page({ Component, pageProps }) {
  const Layout = Component.getLayout || ((page) => page);
  return (
    <ErrorBoundary>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Seo {...pageProps.seo} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <main className={classNames(blricons.variable, 'min-h-fit')}>
            {Layout(<Component {...pageProps} />)}
            <Script src="https://tally.so/widgets/embed.js" />
          </main>
        </PersistGate>
      </Provider>
      {/* BYPASS ERROR APP: CALL THIS BEFORE READY */}
      <button id="refreshpage" style={{ position: 'fixed', top: 0, display: 'none' }} onClick={(e) => { document.getElementById('__next').scrollTop = 10; }} />
    </ErrorBoundary>
  );
}
